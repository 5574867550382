<template>
  <nav class="sidenav-wrapper yb-bg-pane dark:bg-yb-gray-alt-darker sidenav-wrapper-alternative bg-yb-gray-alt-lightest px-0 h-full" :class="{ isOpen: isOpen }">
    <div class="flex items-center justify-center mt-6">
      <ul class="px-2 mx-0 w-full">
        <li v-for="(link, index) in activeLinklist" :key="index" class="nav-item-alt w-full my-1.5" :class="{isActive: isActive(link.path), subNavIsOpen: link.isOpen && isOpen}" @mouseover="$emitter.emit('hideSelectDropdown')">
          <router-link v-if="linkVisible(link)" :to="link.path" class="nav-link-alt text-yb-gray-alt-lighter bg-transparent rounded-full w-12 h-12 p-1 hover:text-yb-gray-alt-dark dark:hover:text-yb-yellow flex items-center" :data-test-id="slugify('nav-item-' + link.title)">
            <v-tooltip placement="right">
              <div class="icon-wrapper px-2">
                <yb-icon :icon="link.icon" :stroke="link.stroke" />
              </div>
              <template #popper>
                {{ link.tooltip }}
              </template>
            </v-tooltip>
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { sync } from 'vuex-pathify'
import { slugify } from '@/filters'

export default {
  computed: {
    linklist: sync('sidenav/linklist', false),
    isOpen: sync('sidenav/settings@isOpen', false),
    subnavOpenIndex: sync('sidenav/settings@subnavOpenIndex', false),
    activeLinklist() {
      // Remove any nav items that are feature toggled off.
      return this.linklist.filter((link) => {
        return !link.toggle || !!this.$toggles[link.toggle]
      })
    }
  },
  mounted() {
    this.handleSubnavState()
    return false && console.log(this.$route)
  },
  methods: {
    slugify,
    toggleNav () {
      this.isOpen = !this.isOpen
    },
    isActive(path) {
      return this.$route.path.startsWith(path)
    },
    handleSubnavClick (index) {
      const linklistclone = this.nestedCopy(this.linklist)
      this.subnavOpenIndex = linklistclone[index].isOpen ? -1 : index
      linklistclone[index].isOpen = !linklistclone[index].isOpen
      this.$store.set('sidenav/linklist', linklistclone)
    },
    handleSubnavState () {
      if (this.subnavOpenIndex === -1) { return }
      const linklistclone = this.nestedCopy(this.linklist)
      linklistclone[this.subnavOpenIndex].isOpen = true
      this.$store.set('sidenav/linklist', linklistclone)
    },
    nestedCopy (array) {
      return JSON.parse(JSON.stringify(array))
    },
    linkVisible(link) {
      let result = true

      if (typeof link.filter === 'function') {
        result &&= link.filter.call(this)
      }

      // Filter on role.
      if (!!result && !!link.role) {
        result &&= this.$hasRole(link.role)
      }

      return result
    }
  }
}
</script>

<style scoped lang="postcss">

.sidenav-wrapper ul > .nav-item:not(.nav-item.isActive):not(:hover) {
  @apply dark:opacity-75;
}

.sidenav-wrapper ul > .nav-item:hover {
  @apply text-yb-gray-medium;
}

.sidenav-wrapper {
  width: 50px;
  transition: all 300ms ease;
  position: relative;
  z-index: 10;
}

.sidenav-wrapper-alternative {
  width: 66px;
  @apply border-r border-yb-gray-alt-lighterer dark:border-yb-gray-alt-lightest-inverse;
}

.sidenav-wrapper.isOpen {
  width: 220px;
  max-width: 220px;
}

.sidenav-wrapper ul > .nav-item {
  position: relative;
  transition: background 500ms ease;
}

.sidenav-wrapper ul > .nav-item.subNavIsOpen {
  @apply bg-white;
}

.sidenav-wrapper ul > .nav-item .nav-link-wrapper {
  align-items: center;
  display: flex;
  padding-right: 12px;
}

.sidenav-wrapper ul > .nav-item .nav-link-wrapper:hover {
  @apply bg-yb-rollover dark:bg-yb-yellow dark:text-yb-black;
}

.sidenav-wrapper ul > .nav-item .nav-link-wrapper .yb-icon {
  margin-left: auto;
}

.sidenav-wrapper ul > .nav-item .nav-link-wrapper .nav-link {
  width: 100%;
}

.sidenav-wrapper ul > .nav-item .nav-link-wrapper .icon-wrapper {
  flex: 0 0 50px;
}

.sidenav-wrapper ul > .nav-item .nav-link-wrapper .icon-wrapper img {
  width: 80%;
  margin: 0 auto;
}

.sidenav-wrapper ul > .nav-item.isActive {
  @apply bg-white;
  @apply font-semibold;
  @apply dark:bg-yb-gray-mud;
}

.sidenav-wrapper ul > .nav-item-alt.isActive > .nav-link-alt {
  @apply bg-yb-gray-alt-darkest dark:bg-yb-gray-alt-medium text-yb-yellow;
}

.secondary-nav {
  padding-left: 50px;
  max-height: 0;
  overflow: hidden;
  transition: all 500ms ease;
}

.subNavIsOpen .secondary-nav {
  display: block;
  max-height: 600px;
}

.secondary-nav li:last-child {
  margin-bottom: 30px;
}

.secondary-nav li.isHeader {
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 10px;
}

.secondary-nav li.isLink {
  margin-left: 15px;
  text-transform: capitalize;
  font-weight: 300;
}

.fade-enter-active, .fade-leave-active {
  transition: all 300ms ease;
  transform-origin: 0 50%;
  transform: scale(1);
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: scale(0);
}

</style>
